<template>
     <div v-if="pagination?.data?.length != 0">
          <div class="flex flex-col -mt-2 mb-5 md:mb-10">
               <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center gap-2">
                         <svg class="w-7" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.0478 16.3295C17.0478 17.0809 17.0493 17.8318 17.0478 18.5832C17.0453 19.6385 16.3551 20.2416 15.1494 20.2421C14.1466 20.2425 13.1444 20.2473 12.1417 20.2403C11.5311 20.236 11.004 20.058 10.6329 19.6119C10.4038 19.3372 10.3293 19.0167 10.3283 18.6861C10.3238 17.1101 10.3158 15.5345 10.3303 13.9589C10.3393 13.0043 11.048 12.4147 12.1502 12.4108C13.1714 12.4073 14.1926 12.4073 15.2139 12.4108C16.3321 12.4143 17.0378 13.0261 17.0463 14.003C17.0528 14.7788 17.0478 15.5541 17.0478 16.3299V16.3295ZM15.3684 18.7768V13.8813H12.0102V18.7768H15.3684Z"
                                   fill="white" />
                              <path d="M9.16979 17.3318C9.16979 17.7726 9.17429 18.2135 9.16879 18.6544C9.15629 19.6299 8.45113 20.2387 7.33089 20.2418C6.31917 20.2448 5.30745 20.2439 4.29573 20.2418C3.15049 20.2391 2.45684 19.6408 2.44933 18.6409C2.44283 17.7513 2.44283 16.8612 2.44933 15.9716C2.45684 14.9721 3.15099 14.3742 4.29673 14.3716C5.29895 14.3694 6.30167 14.3698 7.30388 14.3716C8.45913 14.3733 9.16129 14.9804 9.16929 15.9851C9.17279 16.4343 9.16979 16.883 9.16979 17.3322V17.3318ZM4.1287 15.8377V18.7665H7.48942V15.8377H4.1287Z"
                                   fill="white" />
                              <path d="M23.4948 15.4031C24.2774 15.4667 24.8486 15.9804 24.8936 16.6651C24.9426 17.413 24.9466 18.1674 24.8951 18.9153C24.8411 19.6929 24.1224 20.2262 23.1797 20.2393C22.103 20.2541 21.0252 20.2537 19.9485 20.2393C18.9393 20.2258 18.2646 19.671 18.2171 18.7958C18.1811 18.136 18.1826 17.4732 18.2151 16.8134C18.2596 15.9181 18.9513 15.3651 19.988 15.3534C20.5126 15.3477 21.0372 15.3525 21.5613 15.3525C21.5613 15.366 22.8556 15.3512 23.4948 15.4031ZM23.2467 18.7705V16.8238H19.879V18.7705H23.2467Z"
                                   fill="white" />
                              <path d="M11.9638 9.1912C12.0253 8.86065 12.0804 8.50436 12.1619 8.15288C12.1914 8.02641 12.1509 7.95446 12.0529 7.87422C11.7783 7.64963 11.5112 7.41851 11.2407 7.19C11.1402 7.10496 11.0441 7.02211 11.0961 6.87951C11.1472 6.74039 11.2552 6.68414 11.4157 6.66539C11.7883 6.62178 12.1589 6.56291 12.5315 6.52061C12.6965 6.50185 12.7995 6.44429 12.871 6.30649C13.0196 6.01954 13.1921 5.74175 13.3506 5.45873C13.4237 5.32747 13.5227 5.18531 13.6942 5.22019C13.8162 5.24505 13.9408 5.3628 14.0088 5.46658C14.1948 5.75091 14.3574 6.04745 14.5174 6.34399C14.5734 6.44778 14.6464 6.49357 14.7749 6.50927C15.169 6.5568 15.5636 6.60433 15.9527 6.67454C16.0622 6.69417 16.2088 6.77702 16.2368 6.8577C16.2658 6.94099 16.2023 7.08229 16.1247 7.15337C15.8472 7.41022 15.5416 7.6444 15.259 7.89733C15.2015 7.94879 15.165 8.04996 15.1755 8.12192C15.227 8.46512 15.3091 8.80526 15.3551 9.1489C15.3691 9.254 15.3411 9.42058 15.2635 9.46288C15.168 9.51478 14.9835 9.50039 14.8675 9.45503C14.5114 9.31679 14.1728 9.14454 13.8197 8.99932C13.7447 8.96836 13.6227 8.96923 13.5482 9.0015C13.1851 9.15762 12.8325 9.33249 12.4724 9.49385C12.1659 9.63121 11.9498 9.50954 11.9638 9.19033V9.1912Z"
                                   fill="white" />
                              <path d="M13.6884 12C10.8579 12 8.5553 9.98144 8.5553 7.5C8.5553 5.01856 10.8579 3 13.6884 3C16.519 3 18.8216 5.01856 18.8216 7.5C18.8216 9.98144 16.519 12 13.6884 12ZM13.6884 4.31502C11.6849 4.31502 10.0553 5.74401 10.0553 7.5C10.0553 9.25599 11.6854 10.685 13.6884 10.685C15.6915 10.685 17.3215 9.25599 17.3215 7.5C17.3215 5.74401 15.6915 4.31502 13.6884 4.31502Z"
                                   fill="white" />
                         </svg>

                         <p class="font-roboto font-bold text-white text-sm lg:text-lg">
                              Top games
                         </p>
                    </div>
                    <div class="flex flex-row gap-2">
                         <div @click="onPrev" :class="currentSlide > 0 ? 'cursor-pointer' : 'cursor-not-allowed'">
                              <svg width="23" height="23" viewBox="0 0 23 23" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                   <path :fill="currentSlide > 0 ? '#fff' : '#6A374D'"
                                        d="M7.58219 11.8133C7.14057 11.3634 7.14057 10.6193 7.58219 10.1693L13.3062 4.33745C13.7478 3.88752 14.4782 3.88752 14.9198 4.33745C15.3614 4.78739 15.3614 5.53152 14.9198 5.98146L9.9941 11L14.9028 16.0185C15.3444 16.4685 15.3444 17.2126 14.9028 17.6625C14.4612 18.1125 13.7308 18.1125 13.2892 17.6625L7.56524 11.8307L7.58219 11.8133Z" />
                              </svg>
                         </div>
                         <div @click="onNext" :class="currentSlide < nextLimit ? 'cursor-pointer' : 'cursor-not-allowed'">
                              <svg width="22" height="23" viewBox="0 0 22 23" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                   <path :fill="currentSlide < nextLimit ? '#fff' : '#6A374D'"
                                        d="M14.8177 10.1866C15.2593 10.6366 15.2593 11.3807 14.8177 11.8307L9.09374 17.6625C8.65213 18.1125 7.92175 18.1125 7.48014 17.6625C7.03852 17.2126 7.03852 16.4685 7.48014 16.0185L12.4058 11L7.49714 5.98146C7.05552 5.53152 7.05552 4.78739 7.49714 4.33745C7.93875 3.88752 8.66908 3.88752 9.11069 4.33745L14.8347 10.1693L14.8177 10.1866Z" />
                              </svg>
                         </div>
                    </div>
               </div>
               <div class="mt-2">
                    <Carousel :itemsToShow="3" :wrapAround="false" :mouseDrag="false" :touchDrag="false"
                         snapAlign="start" :transition="500" class="home-slider-list" :breakpoints="showItems"
                         v-model="currentSlide">
                         <Slide v-for="(item, key) in pagination?.data" :key="key">
                              <div class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-out">
                                   <div class="games-aspect-ratio-3-4 relative items-center cursor-pointer">
                                        <img
                                             loading="lazy"
                                             v-if="loading"
                                             :src="baseUrl.img + '/assets/img/loader/games-preloader.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                        >
                                        <img
                                             v-else
                                             loading="lazy"
                                             :src="baseUrl.img + '/assets/img/loader/games-broken.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                        >
                                        <div
                                             class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                             <img loading="lazy" v-if="item.game_pic != null && !loading"
                                                  :src="toWebp(item.game_pic, 'popular')" :alt="item.game_name"
                                                  class="rounded-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit;">
                                             <!-- <img loading="lazy" v-else
                                                  :src="baseUrl.img + '/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto hidden md:block game-thumbnails"> -->
                                             <img loading="lazy" v-if="item.game_pic_mobile != null && !loading"
                                                  :src="toWebp(item.game_pic_mobile, 'popular')" :alt="item.game_name"
                                                  class="rounded-md w-full block md:hidden game-thumbnails"
                                                  style="height: inherit;">
                                             <!-- <img loading="lazy" v-else
                                                  :src="baseUrl.img + '/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto block md:hidden game-thumbnails"> -->
                                        </div>
                                   </div>
                              </div>
                         </Slide>
                    </Carousel>
               </div>
          </div>
     </div>
</template>
<script>
import {
     ref,
     reactive,
     watch,
     computed,
     onMounted
} from "vue";
import { Carousel, Slide } from "vue3-carousel";
import { baseUrl } from "config/services.js";
import { gamesStore } from "store/games";
import { useImage } from "composables/use-image.js";
import _ from "underscore";

export default {
     components: {
          Carousel,
          Slide,
     },
     setup() {
          const currentSlide = ref(0);
          const showItems = ref({
               0: {
                    itemsToShow: 3,
               },
               768: {
                    itemsToShow: 3.5,
               },
               1024: {
                    itemsToShow: 6.5,
               },
               1280: {
                    itemsToShow: 8.5,
               },
               1536: {
                    itemsToShow: 10.5,
               },
               1910: {
                    itemsToShow: 12.5,
               }
          });

          const nextLimit = ref(0);
          const loading = ref(false);

          const useGamesStore = gamesStore();

          const { toWebp } = useImage();

          const pagination = reactive({
               data: [],
          });

          const popularData = computed(() => {
               if (useGamesStore.popular) {
                    pagination.data = useGamesStore.popular["data"];
                    setNextLimit();
               }
               return useGamesStore.popular || null;
          });

          const onNext = () => {
               const current = currentSlide.value
               if (current < nextLimit.value) {
                    currentSlide.value = current + 1;
               }
          }

          const onPrev = () => {
               const current = currentSlide.value
               if (current > 0) {
                    currentSlide.value = current - 1;
               }
          }

          const goToDetail = (name, id) => {
               router.push({
                    path:
                         "/games/detail/" +
                         name.replaceAll(" ", "-") +
                         "-" +
                         id,
               });
          };

          const openLogin = () => {
               router.push({ name: "login" });
          };

          const adjustNextLimit = () => {
               window.addEventListener("resize", () => {
                    currentSlide.value = 0
                    setNextLimit()
               });
          }

          const setNextLimit = () => {
               const getWidth = window.innerWidth;

               const find = _.filter(Object.keys(showItems.value), function (params) {
                    return getWidth >= parseInt(params)
               })

               let size = parseInt(find.pop());
               size = Math.round(showItems.value[size]['itemsToShow']);

               nextLimit.value = size >= pagination.data.length ? 0 : pagination.data.length - size;
          }

          onMounted(async () => {
               adjustNextLimit();
          });

          watch(popularData, (newVal) => {
               if (newVal) {
                    loading.value = true;
                    pagination.data = newVal["data"];
                    setNextLimit();
                    setTimeout(() => {
                         loading.value = false;
                    }, 2000);
               }
          });

          return {
               baseUrl,
               onNext,
               onPrev,
               currentSlide,
               goToDetail,
               pagination,
               openLogin,
               toWebp,
               showItems,
               nextLimit,
               loading
          }
     }
}
</script>
