<template>
     <Paginate
          :data="games"
          title=""
          :onSeeMore="onSeeMore"
          :pagination="pagination"
     >
          <template v-slot:icon>
               
               <p class="font-roboto font-bold text-white text-sm lg:text-lg">
                    Search Results for "{{search}}"
               </p>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Paginate from "components/games/paginate/index.vue";

export default {
     components: {
          Paginate
     },
     props: ['search', 'provider'],
     setup(props) {

          const useGamesStore = gamesStore();

          const games = reactive({
               data: null,
          });

          const params = reactive({});

          const pagination = reactive({
               current: null,
               last: null,
               next: null,
          });

          const gamesData = computed(() => {
               if (useGamesStore.allGames['result']) {
                    games.data = useGamesStore.allGames['result']["data"];
                    pagination['current'] = useGamesStore.allGames['result']["current_page"]
                    pagination['last'] = useGamesStore.allGames['result']["last_page"]
                    const temp = useGamesStore.allGames['result']["next_page_url"];
                    pagination['next'] = temp != null ? temp.slice(-1) : pagination['last']
               }
               return useGamesStore.allGames['result'] || null;
          });

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]]
               }

               return useGamesStore.gameFilter;
          });

          const onSeeMore = async () => {
               await useGamesStore.getGames(
                    {
                         "game_name": props.search,
                         "game_promo": 0,
                         "game_type": [],
                         "provider_id": props.provider,
                         "page": pagination['next'],
                         "itemsPerPage": 21,
                         "sortType": "popularity"
                    }, "result"
               );
          }

          watch(gamesData, (newVal) => {
               if (newVal['result']) {
                    games.data = newVal['result']["data"];
                    pagination['current'] = newVal['result']["current_page"]
                    pagination['last'] = newVal['result']["last_page"]
                    const temp = newVal['result']["next_page_url"];
                    pagination['next'] = temp != null ? temp.slice(-1) : pagination['last']
               }
          });

          watch(gameFilter, () => {
          });

          return {
               games,
               onSeeMore,
               pagination
          }
     }
}
</script>