<template>
     <div class="my-3">
          <div class="w-full h-11 shadow-lg flex flex-row items-center gap-2 relative">
               <SearchGame :onSearch="onSearch" :onClear="onClear"/>
               <DropdownGame
                    :provider="provider"
                    :isDropdown="isDropdown"
                    :onDropdown="(val) => { onDropdown(val) }"
                    :selectedDropdown="selectedDropdown"
                    :onSelect="onSelect"
                    :hideDropdown="hideDropdown"
               />
               <SortByGame
                    :params="params"
                    :sortByOpen="sortByOpen"
                    :onSortByOpen="onSortByOpen"
                    :onSort="onSort"
               />
          </div>
     </div>
</template>
<script>
import SearchGame from "./components/search/index.vue";
import DropdownGame from "./components/dropdown/index.vue";
import SortByGame from "./components/sortby/index.vue";

export default {
     props: [
          'onSearch',
          'onClear',
          'provider',
          'isDropdown',
          'onDropdown',
          'selectedDropdown',
          'onSelect',
          'hideDropdown',
          'params',
          'sortByOpen',
          'onSortByOpen',
          'onSort',

     ],
     components: {
          SearchGame,
          DropdownGame,
          SortByGame,
     },
     setup() {
     },
};
</script>