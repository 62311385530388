<template>
     <FilterGame
          :onSearch="onSearch"
          :onClear="onClear"
          :provider="providerData"
          :isDropdown="isDropdown"
          :onDropdown="(val) => { isDropdown = (val != null ? val : !isDropdown) }"
          :selectedDropdown="selectedDropdown"
          :onSelect="onSelect"
          :hideDropdown="true"
          :params="params"
          :sortByOpen="sortByOpen"
          :onSortByOpen="(val) => { sortByOpen = (val != null ? val : !sortByOpen) }"
          :onSort="onSort"
     />
     <CategoryGame
          v-if="search == ''"
          :game_type="game_type"
          :isUser="isUser"
          :onSetActive="onSetActive"
          :active="active"
     />
     <Recent
          v-if="isUser && ['recent', null].includes(active) && search == ''"
          :type="active == 'recent' ? 'paginate' : 'slider'"
     />
     <!-- <Exclusive/> -->
     <Top v-if="active == null && search == ''" />
     <Favorite
          v-if="isUser && ['favorite', null].includes(active) && search == ''"
          :type="active == 'favorite' ? 'paginate' : 'slider'"
     />
     <div v-if="search == ''">
          <Custom
               v-for="(item, key) in groupData"
               :key="key"
               :active="item['game_type'].replaceAll(' ', '-')"
               :type="active == item['game_type'].replaceAll(' ', '-') ? 'paginate' : (active == null ? 'slider' : '')"
          />
     </div>
     <All v-if="['all', null].includes(active) && search == ''" />
     <Result
          v-if="search != ''"
          :search="search"
          :provider="providerData"
     />
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { gamesStore } from "store/games";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { useRoute, useRouter } from "vue-router";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";
import { utilitiesStore } from "store/utilities.js";
import FilterGame from "./filter/index.vue";
import CategoryGame from "./category/index.vue";
import Recent from "./list/recent/index.vue";
import Favorite from "./list/favorite/index.vue";
// import Exclusive from "./list/exclusive/index.vue";
import Top from "./list/top/index.vue";
import All from "./list/all/index.vue";
import Custom from "./list/custom/index.vue";
import Result from "./list/result/index.vue";

export default {
     components: {
          FilterGame,
          CategoryGame,
          Recent,
          Favorite,
          Top,
          All,
          Custom,
          Result
     },
     setup() {
          const { money } = useFormatter();
          const route = useRoute();
          const router = useRouter();
          const { scrollTop } = useScroll();

          const banner = ref(null);

          const isDropdown = ref(false);

          const search = ref('');

          const params = reactive({
               page: 1,
               itemsPerPage: 20,
               game_type: [],
               game_promo: 0,
               game_name: "",
               provider_id: [],
               sortType: "popularity",
          });

          // const games = reactive({});

          const selfExclusion = ref(null);

          const game_type = ref(null);

          const selectedDropdown = ref([]);

          // all
          const active = ref(null);

          const all_count = ref(0);

          const loading = ref(false);

          const sortByOpen = ref(false);

          const useAuthStore = authStore();
          const useGamesStore = gamesStore();
          const useUtilitiesStore = utilitiesStore();

          let timer,
               timeoutVal = 1000;

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]]
               }

               active.value = useGamesStore.gameFilter['active']

               return useGamesStore.gameFilter;
          });

          const groupData = computed(() => {
               if (useGamesStore.groups) {
                    if (route.path.split("/")[2] == "type") {
                         const filter = _.filter(
                              useGamesStore.groups,
                              (params) => {
                                   return (
                                        params["game_type"].toLowerCase() ==
                                        route.path.split("/")[3]
                                   );
                              }
                         );
                         if (filter.length != 0) {
                              active.value = filter[0]["game_type"].replaceAll(' ', '-');
                              params.game_type = [filter[0]["game_type"]];
                         }
                    }
               }
               if (useGamesStore.groups) {
                    game_type.value = useGamesStore.groups;
               }
               return useGamesStore.groups || null;
          });

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const errorImages = ref([]);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const onImageError = (e) => {
               let temp = errorImages.value;
               temp.push(parseInt(e.target.alt));

               const uniq = _.uniq(temp);

               errorImages.value = uniq;

               e.target.src = "assets/img/games/default.png";
          };

          const onSetActive = async (data) => {
               active.value = data;
          };

          const onSearch = (query) => {
               window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
               timer = window.setTimeout(async () => {

                    if (query.toLowerCase() == '') {
                         search.value = ''
                         useGamesStore.clearResult();
                    }

                    search.value = query.toLowerCase();

                    const temp = {
                         "game_name": query.toLowerCase(),
                         "game_promo": 0,
                         "game_type": [],
                         "provider_id": params.provider_id,
                         "page": 1,
                         "itemsPerPage": 21,
                         "sortType": params.sortType
                    }

                    await useGamesStore.getGames(temp, "result", true);

               }, timeoutVal);
          };

          const onClear = () => {
               search.value = '';
               useGamesStore.clearResult();
          }

          const onSelect = async (id) => {
               let data = selectedDropdown.value;

               if (id == 0) {
                    console.log(Object.keys(data));
                    console.log(Object.keys(data).length == 0);

                    if (Object.keys(data).length == 0) {
                         console.log('full');

                         data = _.pluck(providerData.value, "id");
                         selectedDropdown.value = data;
                         params.provider_id = data;
                    } else {
                         if (Object.keys(data).length != providerData.value.length) {
                              data = _.pluck(providerData.value, "id");
                              selectedDropdown.value = data;
                              params.provider_id = data;
                         } else {
                              console.log('empty');
                              selectedDropdown.value = [];
                              params.provider_id = [];
                         }
                    }
               } else {
                    if (data.includes(id)) {
                         if (data.includes(0)) {
                              data.splice(data.indexOf(0), 1);
                         }
                         data.splice(data.indexOf(id), 1);
                         selectedDropdown.value = data;
                         params.provider_id = data;
                    } else {
                         data.push(id);
                         if (data.length == providerData.value.length) {
                              selectedDropdown.value = [0, ...data];
                         } else {
                              selectedDropdown.value = data;
                         }
                         params.provider_id = data;
                    }
               }

               if (search.value != '') {

                    const temp = {
                         "game_name": search.value.toLowerCase(),
                         "game_promo": 0,
                         "game_type": [],
                         "provider_id": params.provider_id,
                         "page": 1,
                         "itemsPerPage": 21,
                         "sortType": params.sortType
                    }

                    await useGamesStore.getGames(temp, "result", true);

                    return;
               }

               params.page = 1;

               await useGamesStore.getPopular({ page: 1, itemsPerPage: 20, sortType: params.sortType });

               const temp = params;
               temp['game_type'] = [];

               if (isUser.value) {
                    await useGamesStore.getRecent(params, true);
                    await useGamesStore.getFavorites(params, true);
               }

               await useGamesStore.getGames(temp, "all", true);

               if (groupData.value) {
                    for (let index = 0; index < groupData.value.length; index++) {
                         const gameType = groupData.value[index]['game_type'].replaceAll(' ', '-');
                         const temp = params;
                         temp['game_type'] = [groupData.value[index]['game_type']];
                         await useGamesStore.getGames(temp, gameType, true);
                    }
               }
          };

          const onSort = async (value) => {
               params.sortType = value
               params.page = 1;

               sortByOpen.value = false;

               if (search.value != '') {
                    
                    const temp = {
                         "game_name": search.value.toLowerCase(),
                         "game_promo": 0,
                         "game_type": [],
                         "provider_id": params.provider_id,
                         "page": 1,
                         "itemsPerPage": 21,
                         "sortType": params.sortType
                    }

                    await useGamesStore.getGames(temp, "result", true);

                    return;
               }

               await useGamesStore.getPopular({ page: 1, itemsPerPage: 20, sortType: value });

               const temp = params;
               temp['game_type'] = [];

               if (isUser.value) {
                    await useGamesStore.getRecent(temp, true);
                    await useGamesStore.getFavorites(temp, true);
               }

               await useGamesStore.getGames(temp, "all", true);

               if (groupData.value) {
                    for (let index = 0; index < groupData.value.length; index++) {
                         const gameType = groupData.value[index]['game_type'].replaceAll(' ', '-');
                         const temp = params;
                         temp['game_type'] = [groupData.value[index]['game_type']];
                         await useGamesStore.getGames(temp, gameType, true);
                    }
               }
          };

          const openLogin = () => {
               router.push({ name: "login" });
          };

          const gotoPromotion = (item) => {
               router.push({
                    path:
                         "/promotion/detail/" +
                         item.title.replaceAll(" ", "-") +
                         "-" +
                         item.title_highlight.replaceAll(" ", "-") +
                         "-" +
                         item.id,
               });
          };

          onMounted(async () => {
               if (!preloading.value) {
                    loading.value = true
               }
               scrollTop();

               await useGamesStore.getProvider();

               console.log(providerData.value);
               
               if (route.path.split("/")[2] == "provider") {
                    const name = route.path.split("/")[3].replaceAll("-", " ").toLowerCase()
                    const filter = _.filter(
                         providerData.value,
                         (value) => {
                              return (
                                   value["name"].toLowerCase() == name
                              );
                         }
                    );
                    if (filter.length != 0) {
                         params.provider_id = [filter[0]["id"]];
                         selectedDropdown.value = params.provider_id
                    } else {
                         params.provider_id = _.pluck(providerData.value, "id");
                         selectedDropdown.value = params.provider_id
                    }
               } else {
                    params.provider_id = _.pluck(providerData.value, "id");
                    selectedDropdown.value = params.provider_id
               }

               await useGamesStore.getGameType();

               if (route.path.split("/")[2] == "type") {
                    const filter = _.filter(groupData.value, (params) => {
                         return (
                              params["game_type"].toLowerCase() ==
                              route.path.split("/")[3]
                         );
                    });
                    if (filter.length != 0) {
                         params.game_type = [filter[0]["game_type"]];
                    }
               }

               const temp = params;
               temp['game_type'] = [];

               if (isUser.value) {
                    await useGamesStore.getRecent(temp, true,);

                    await useGamesStore.getFavorites(temp, true,);
               }

               await useGamesStore.getGames(temp, "all", true,);

               if (groupData.value) {
                    for (let index = 0; index < groupData.value.length; index++) {
                         const gameType = groupData.value[index]['game_type'].replaceAll(' ', '-');
                         const temp = params;
                         temp['game_type'] = [groupData.value[index]['game_type']];
                         await useGamesStore.getGames(temp, gameType, true,);
                    }
               }

          });

          watch(groupData, (newVal) => {
               if (newVal) {
                    game_type.value = newVal;
                    if (route.path.split("/")[2] == "type") {
                         const filter = _.filter(newVal, (params) => {
                              return (
                                   params["game_type"].toLowerCase() ==
                                   route.path.split("/")[3]
                              );
                         });
                         if (filter.length != 0) {
                              active.value = filter[0]["game_type"].replaceAll(' ', '-');
                              params.game_type = [filter[0]["game_type"]];
                         }
                    }
               }
          });

          watch(gameFilter, (newVal) => {
               if (newVal) {
                    const obj = Object.keys(newVal);
                    for (let index = 0; index < obj.length; index++) {
                         params[obj[index]] = newVal[obj[index]]
                    }

                    active.value = newVal['active']
               }
          });

          return {
               isDropdown,
               active,
               onSetActive,
               params,
               onImageError,
               isUser,
               game_type,
               onSearch,
               errorImages,
               selectedDropdown,
               onSelect,
               openLogin,
               all_count,
               money,
               loading,
               banner,
               selfExclusion,
               sortByOpen,
               onSort,
               baseUrl,
               gotoPromotion,
               groupData,
               search,
               onClear,
               providerData
          };
     }
}
</script>