<template>
     <div
          class="w-max px-3 lg:px-5 h-full bg-moozi-2 rounded-full flex cursor-pointer lg:relative border border-solid border-moozi-4">
          <div @click="onDropdown()"
               class="flex flex-row gap-3 justify-center items-center w-full lg:w-full dropdown-trigger">
               <svg :class="isDropdown ? 'hidden lg:block' : ''" class="w-4 " viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" fill="#3B0F11" />
                    <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" stroke="white" />
                    <path d="M5 9.47826L9.81481 14L15 6" stroke="white" stroke-width="2" stroke-linecap="round"
                         stroke-linejoin="round" />
               </svg>
               <svg
                    :class="isDropdown ? 'block lg:hidden' : 'hidden'"
                    class="w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M9 8L15 16M15 8L9 16M12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21Z"
                         stroke="white"
                         stroke-width="2"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                    />
               </svg>
               <p
                    class="font-roboto font-medium text-white text-sm xl:text-base capitalize hidden lg:block dropdown-trigger-text text-nowrap overflow-hidden">
                    Game Studios ({{ selectedDropdown.length }})
               </p>
          </div>
          <div v-if="isDropdown"
               class="absolute max-h-52 lg:max-h-48 overflow-y-auto z-20 bg-moozi-2 flex flex-col justify-start gap-1 lg:gap-2 rounded-xl dropdown-provider w-full lg:w-44 xl:w-full top-14 lg:top-12 right-0 border border-solid border-moozi-4">
               <div 
                    v-for="(item, key) in provider"
                    :key="key" 
                    @click="onSelect(item.id)" 
                    class="dropdown-list flex flex-row gap-3 items-center px-3 py-2"
               >
                    <Check v-if="selectedDropdown.includes(item.id)" />
                    <Uncheck v-else />
                    <p class="font-roboto font-normal text-white text-sm lg:text-base dropdown-text">{{ item.name == 'All' ? ((selectedDropdown.includes(0) ? 'Unselect ' : 'Select ') + item.name) : item.name }}</p>
               </div>
          </div>
     </div>
</template>
<script>
import Check from "components/checkbox/check.vue";
import Uncheck from "components/checkbox/uncheck.vue";
import { onMounted } from 'vue';

export default {
     props: ['provider', 'isDropdown', 'onDropdown', 'selectedDropdown', 'onSelect', 'hideDropdown'],
     components: {
          Check,
          Uncheck,
     },
     setup(props) {

          onMounted(async () => {
               document.addEventListener("click", (evt) => {
                    const sortTrigger1 = document.querySelector(".dropdown-provider");
                    const sortTrigger2 = document.querySelector(".dropdown-list");
                    const sortTrigger3 = document.querySelector(".dropdown-check");
                    const sortTrigger4 = document.querySelector(".dropdown-text");
                    const sortTrigger5 = document.querySelector(".dropdown-trigger");
                    const sortTrigger6 = document.querySelector(".dropdown-trigger-text");
                    const sortTrigger7 = document.querySelector(".dropdown-trigger-down");

                    let targetElSort = evt.target; // clicked element

                    if (targetElSort != null) {
                         do {
                              if (
                                   targetElSort == sortTrigger1 ||
                                   targetElSort == sortTrigger2 ||
                                   targetElSort == sortTrigger3 ||
                                   targetElSort == sortTrigger4 ||
                                   targetElSort == sortTrigger5 ||
                                   targetElSort == sortTrigger6 ||
                                   targetElSort == sortTrigger7
                              ) {
                                   return;
                              }
                              targetElSort = targetElSort.parentNode;
                         } while (targetElSort);
                    }

                    props.onDropdown(false)
               });
          })

          return {
          };
     },
};
</script>