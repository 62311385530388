<template>
     <div v-if="data?.data?.length != 0">
          <div class="flex flex-col -mt-2 mb-5 md:mb-10">
               <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center gap-2">
                         <slot name="icon"></slot>
                         <p class="font-roboto font-bold text-white text-sm lg:text-lg capitalize">
                              {{ title }}
                         </p>
                    </div>
                    <div
                         v-if="!loading"
                         class="flex flex-row gap-2"
                    >
                         <div
                              @click="onPrev"
                              :class="currentSlide > 0 ? 'cursor-pointer' : 'cursor-not-allowed'"
                         >
                              <svg
                                   width="23"
                                   height="23"
                                   viewBox="0 0 23 23"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        :fill="currentSlide > 0 ? '#fff' : '#6A374D'"
                                        d="M7.58219 11.8133C7.14057 11.3634 7.14057 10.6193 7.58219 10.1693L13.3062 4.33745C13.7478 3.88752 14.4782 3.88752 14.9198 4.33745C15.3614 4.78739 15.3614 5.53152 14.9198 5.98146L9.9941 11L14.9028 16.0185C15.3444 16.4685 15.3444 17.2126 14.9028 17.6625C14.4612 18.1125 13.7308 18.1125 13.2892 17.6625L7.56524 11.8307L7.58219 11.8133Z"
                                   />
                              </svg>
                         </div>
                         <div
                              @click="onNext"
                              :class="currentSlide < nextLimit ? 'cursor-pointer' : 'cursor-not-allowed'"
                         >
                              <svg
                                   width="22"
                                   height="23"
                                   viewBox="0 0 22 23"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        :fill="currentSlide < nextLimit ? '#fff' : '#6A374D'"
                                        d="M14.8177 10.1866C15.2593 10.6366 15.2593 11.3807 14.8177 11.8307L9.09374 17.6625C8.65213 18.1125 7.92175 18.1125 7.48014 17.6625C7.03852 17.2126 7.03852 16.4685 7.48014 16.0185L12.4058 11L7.49714 5.98146C7.05552 5.53152 7.05552 4.78739 7.49714 4.33745C7.93875 3.88752 8.66908 3.88752 9.11069 4.33745L14.8347 10.1693L14.8177 10.1866Z"
                                   />
                              </svg>
                         </div>
                    </div>
               </div>
               <div class="mt-2">
                    <Carousel
                         :itemsToShow="3"
                         :wrapAround="false"
                         :mouseDrag="false"
                         :touchDrag="false"
                         snapAlign="start"
                         :transition="500"
                         class="home-slider-list"
                         :breakpoints="showItems"
                         v-model="currentSlide"
                    >
                         <Slide
                              v-for="(item, key) in data?.data"
                              :key="key"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-out">
                                   <div class="games-aspect-ratio-1-1 relative items-center cursor-pointer">
                                        <img
                                             loading="lazy"
                                             v-if="loading"
                                             :src="baseUrl.img + '/assets/img/loader/games-preloader.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                        >
                                        <img
                                             v-else
                                             loading="lazy"
                                             :src="baseUrl.img + '/assets/img/loader/games-broken.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                        >
                                        <div
                                             class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                             <img
                                                  loading="lazy"
                                                  v-if="item.game_pic != null && !loading"
                                                  :src="toWebp(item.game_pic, 'games')"
                                                  :alt="getProvider(item.provider_id) + '-' + item.game_pic"
                                                  class="rounded-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit;"
                                             >
                                             <!-- <img
                                                  loading="lazy"
                                                  v-else-if="loading"
                                                  :src="baseUrl.img + '/assets/img/loader/games-preloader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto hidden md:block game-thumbnails"
                                             >
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="baseUrl.img + '/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto hidden md:block game-thumbnails"
                                             > -->
                                             <img
                                                  loading="lazy"
                                                  v-if="item.game_pic_mobile != null && !loading"
                                                  :src="toWebp(item.game_pic_mobile, 'games')"
                                                  :alt="getProvider(item.provider_id) + '-' + item.game_pic"
                                                  class="rounded-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit;"
                                             >
                                             <!-- <img
                                                  loading="lazy"
                                                  v-else-if="loading"
                                                  :src="baseUrl.img + '/assets/img/loader/games-preloader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto block md:hidden game-thumbnails"
                                             >
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="baseUrl.img + '/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto block md:hidden game-thumbnails"
                                             > -->
                                        </div>
                                   </div>
                              </div>
                         </Slide>
                    </Carousel>
                    <!-- <Carousel
                         v-if="loading"
                         :itemsToShow="3"
                         :wrapAround="false"
                         :mouseDrag="false"
                         :touchDrag="false"
                         snapAlign="start"
                         :transition="500"
                         class="home-slider-list"
                         :breakpoints="showItems"
                         v-model="currentSlide"
                    >
                         <Slide
                              v-for="(item, key) in 7"
                              :key="key"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-out">
                                   <div class="games-aspect-ratio-1-1 relative items-center cursor-pointer">
                                        <img
                                             loading="lazy"
                                             :src="baseUrl.img + '/assets/img/loader/popular-loader.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                        >
                                        <div
                                             class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                             <img
                                                  loading="lazy"
                                                  :src="baseUrl.img + '/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto hidden md:block game-thumbnails"
                                             >
                                        </div>
                                   </div>
                              </div>
                         </Slide>
                    </Carousel> -->
               </div>
          </div>
     </div>
</template>
<script>
import { useImage } from "composables/use-image.js";
import { baseUrl } from "config/services.js";
import { ref, onMounted, computed, toRefs, watch } from "vue";
import { gamesStore } from "store/games";
import _ from "underscore";
import { Carousel, Slide } from "vue3-carousel";

export default {
     components: {
          Carousel,
          Slide,
     },
     props: ['data', 'title'],
     setup(props) {
          const useGamesStore = gamesStore();

          const { data } = toRefs(props)

          const loading = ref(false);

          const currentSlide = ref(0);
          const showItems = ref({
               0: {
                    itemsToShow: 3,
               },
               1024: {
                    itemsToShow: 4,
               },
               1280: {
                    itemsToShow: 6,
               },
               1536: {
                    itemsToShow: 7,
               },
          });

          const nextLimit = ref(0);

          const { toWebp } = useImage();

          const onNext = () => {
               const current = currentSlide.value
               if (current < nextLimit.value) {
                    currentSlide.value = current + 1;
               }
          }

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const onPrev = () => {
               const current = currentSlide.value
               if (current > 0) {
                    currentSlide.value = current - 1;
               }
          }

          const adjustNextLimit = () => {
               window.addEventListener("resize", () => {
                    currentSlide.value = 0
                    setNextLimit()
               });
          }

          const setNextLimit = () => {
               const getWidth = window.innerWidth;

               const find = _.filter(Object.keys(showItems.value), function (params) {
                    return getWidth >= parseInt(params)
               })

               let size = parseInt(find.pop());
               size = Math.round(showItems.value[size]['itemsToShow']);

               nextLimit.value = size >= data?.value?.data?.length ? 0 : data?.value?.data?.length - size;
          }

          const getProvider = (id) => {
               const filter = _.filter(providerData.value, (params) => {
                    return params['id'] == id
               })

               return filter[0]['name']
          }

          onMounted(async () => {
               setNextLimit();
               adjustNextLimit();
          });

          watch(props.data, (newVal) => {
               if (newVal) {
                    loading.value = true;
                    setNextLimit()
                    setTimeout(() => {
                         loading.value = false;
                    }, 2000);
               }
          });

          return {
               toWebp,
               baseUrl,
               onNext,
               onPrev,
               currentSlide,
               showItems,
               nextLimit,
               getProvider,
               loading
          }
     }
}
</script>