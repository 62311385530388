

<template>
     <InertiaHead title="Home"></InertiaHead>
     <Carousel/>
     <Games/>
     <Promotions/>
     <Benefits/>
     <Providers/>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { verifyAccount } from "api/verify.js";
import { authStore } from "store/auth.js";
import { computed, onMounted } from "vue";
import { useToaster } from "composables/use-toaster.js";
import { useRouter } from "vue-router";
import { promoStore } from "store/promotion";
import { gamesStore } from "store/games";
import { useScroll } from "composables/use-scroll.js";
import Carousel from "./components/carousel/index.vue";
import Games from "./components/games/index.vue";
import Promotions from "./components/promotions/index.vue";
import Benefits from "./components/benefits/index.vue";
import Providers from "./components/providers/index.vue";

export default {
     components: {
          InertiaHead,
          Carousel,
          Games,
          Promotions,
          Providers,
          Benefits
     },
     setup() {
          const router = useRouter();
          
          const { scrollTop } = useScroll();

          const useGamesStore = gamesStore();
          const usePromoStore = promoStore();
          const useAuthStore = authStore();

          const { successToast, errorToast } = useToaster();
          const promoData = computed(() => {
               return usePromoStore.promo || null;
          });

          onMounted(async () => {
               scrollTop();
               console.log('=======================lobbyyy=======================');
               
               await usePromoStore.getPromotion({published_at: 'promotion'});
               await usePromoStore.getPromotion({ published_at: "games" });
               await useGamesStore.getPopular({ page: 1, itemsPerPage: 20, sortType: 'popularity' });
               await useGamesStore.getAllProvider();
               await useGamesStore.getProvider();

               if (
                    new URL(location.href).searchParams.get(
                         "account_activation"
                    )
               ) {
                    await verifyAccount(
                         new URL(location.href).searchParams.get(
                              "account_activation"
                         )
                    ).then(async (data) => {
                         if (data.status) {
                              await useAuthStore.getUser(true);
                              successToast(data.message, "top-right");
                              router.push({ path: "/" });
                         } else {
                              errorToast(data.message, "top-right");
                              router.push({ path: "/" });
                         }
                    });
               }
          });


          const isUser = computed(() => {
               return useAuthStore.user;
          });

          return {
               isUser,
               promoData,
               // viewportHeight,
               // clientHeight
          };
     },
};
</script>
