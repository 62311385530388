<template>
     <div>
          <div class="flex flex-col -mt-2 mb-8 md:mb-14">
               <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center gap-2">
                         <slot name="icon"></slot>
                         <p class="font-roboto font-bold text-white text-sm lg:text-lg capitalize">
                              {{ title }}
                         </p>
                    </div>
               </div>
               <div
                    v-if="data?.data?.length != 0"
                    class="mt-2"
               >
                    <div class="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 gap-3">
                         <div
                              v-for="(item, key) in data?.data"
                              :key="key"
                         >
                              <!-- @click="() => goToDetail(item.game_name, item.id)" -->
                              <div
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-out">
                                   <div class="games-aspect-ratio-1-1 relative items-center cursor-pointer">
                                        <img
                                             loading="lazy"
                                             v-if="loading"
                                             :src="baseUrl.img + '/assets/img/loader/games-preloader.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                        >
                                        <img
                                             v-else
                                             loading="lazy"
                                             :src="baseUrl.img + '/assets/img/loader/games-broken.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                        >
                                        <div
                                             class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                             <img
                                                  loading="lazy"
                                                  v-if="item.game_pic != null"
                                                  :src="toWebp(item.game_pic, 'games')"
                                                  :alt="getProvider(item.provider_id) + '-' + item.game_pic"
                                                  class="rounded-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit;"
                                             >
                                             <!-- <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="baseUrl.img + '/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto hidden md:block game-thumbnails"
                                             > -->
                                             <img
                                                  loading="lazy"
                                                  v-if="item.game_pic_mobile != null"
                                                  :src="toWebp(item.game_pic_mobile, 'games')"
                                                  :alt="getProvider(item.provider_id) + '-' + item.game_pic"
                                                  class="rounded-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit;"
                                             >
                                             <!-- <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="baseUrl.img + '/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-md w-full h-auto block md:hidden game-thumbnails"
                                             > -->
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-else-if="data.data.length == 0 && !loading"
                    class="mt-2"
               >
                    <div
                         class="bg-moozi-2 rounded-xl flex justify-center items-center font-roboto font-semibold text-sm lg:text-base text-moozi-4 py-5 md:py-10 px-4 md:px-10">
                         Sorry! No Games Available
                    </div>
               </div>
               <div v-else class="mt-2">
                    <div class="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 gap-3">
                         <div
                              v-for="(item, key) in 7"
                              :key="key"
                         >
                              <div
                                   class="game-card rounded-xl w-full h-max relative mx-1.5 scale-100 hover:scale-105 transition-transform duration-300 ease-out">
                                   <div class="games-aspect-ratio-1-1 relative items-center cursor-pointer">
                                        <img
                                             loading="lazy"
                                             v-if="loading"
                                             :src="baseUrl.img + '/assets/img/loader/games-preloader.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-md w-full h-full game-thumbnails"
                                        >
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-if="data?.data?.length != 0 && pagination?.current < pagination?.last"
                    class="flex justify-center items-center mt-7"
               >
                    <div
                         @click="onSeeMore()"
                         class="h-10 sm:h-11 flex flex-row items-center gap-1 rounded-full transition-transform  duration-300 font-roboto font-bold tracking-wider px-14 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                    >
                         <span class="cursor-pointer font-roboto text-sm lg:text-lg font-bold">See more</span>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { computed, watch, ref } from "vue";
import { gamesStore } from "store/games";
import _ from "underscore";
import { useImage } from "composables/use-image.js";
import { baseUrl } from "config/services.js";

export default {
     components: {
     },
     props: ['data', 'title', 'onSeeMore', 'pagination'],
     setup(props) {
          const useGamesStore = gamesStore();

          const { toWebp } = useImage();

          const loading = ref(false);

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const getProvider = (id) => {
               const filter = _.filter(providerData.value, (params) => {
                    return params['id'] == id
               })

               return filter[0]['name']
          }

          watch(props.data, (newVal) => {
               if (newVal) {
                    loading.value = true;
                    setTimeout(() => {
                         loading.value = false;
                    }, 3000);
               }
          });

          return {
               baseUrl,
               toWebp,
               getProvider,
               loading
          }
     }
}
</script>
