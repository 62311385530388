<template>
     <div class="mb-3">
          <div v-if="game_type != null"
               class="game-tab-wrapper flex flex-row gap-2 overflow-x-auto overflow-y-hidden h-13 pb-3">
               <div key="0" :class="active != null ? 'w-max opacity-100' : 'w-0 opacity-0'" class="transition-all ease-in-out duration-300">
                    <div @click="() => onSetActive(null)" v-if="active != null"
                         class="w-full h-10 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid border-moozi-4">
                         <span class="icon-allgames text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-sm lg:text-base capitalize whitespace-nowrap">
                              Lobby</p>
                    </div>
               </div>
               <!-- <div key="0">
                    <div @click="() => onSetActive('hot')"
                         class="w-full h-10 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="active == 'hot' ? 'active border-transparent' : 'border-moozi-4'">
                         <span class="icon-hot text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-sm lg:text-base capitalize whitespace-nowrap">
                              Hot Games</p>
                    </div>
               </div> -->
               <div v-if="isUser" key="1">
                    <div @click="() => onSetActive('favorite')"
                         class="w-full h-10 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="active == 'favorite' ? 'active border-transparent' : 'border-moozi-4'">
                         <span class="icon-chevron-fav text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-sm lg:text-base capitalize whitespace-nowrap">
                              Favorite
                         </p>
                    </div>
               </div>
               <div v-if="isUser" key="2">
                    <div @click="() => onSetActive('recent')"
                         class="w-full h-10 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="active == 'recent' ? 'active border-transparent' : 'border-moozi-4'">
                         <span class="icon-chevron-recent text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-sm lg:text-base capitalize whitespace-nowrap">
                              Recent
                         </p>
                    </div>
               </div>
               <div key="3">
                    <div @click="() => onSetActive('all')"
                         class="w-full h-10 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="active == 'all' ? 'active border-transparent' : 'border-moozi-4'">
                         <span class="icon-allgames text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-sm lg:text-base capitalize whitespace-nowrap">
                              All Games</p>
                    </div>
               </div>
               <div v-for="(item, key) in game_type" :key="key + 4">
                    <div @click="() => onSetActive(item.game_type.replaceAll(' ', '-'))"
                         class="w-full h-10 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="active == item.game_type.replaceAll(' ', '-') ? 'active border-transparent' : 'border-moozi-4'">
                         <span class="text-3xl" :class="'icon-' + item.game_type.replaceAll(' ', '-')"></span>
                         <p
                              class="font-roboto font-bold text-white text-sm lg:text-base capitalize whitespace-nowrap">
                              <!-- :class="item.game_type.length > 7 ? 'text-xxs md:text-xs lg:text-xs xl:text-base' : 'text-xs md:text-xs lg:text-sm xl:text-lg'" -->
                              {{ item.game_type }}
                         </p>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
export default {
     props: ['game_type', 'isUser', 'onSetActive', 'active', 'isExpand', 'onExpand'],
     components: {
     },
     setup() {

          return {
          };
     },
};
</script>
