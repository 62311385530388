<template>
     <div v-if="banner" class="w-full">
          <Carousel
               :itemsToShow="1"
               snapAlign="center"
               :transition="1000"
               key="games-banner-carousel"
               class="w-full games-banner-carousel"
               :autoplay="2000"
          >
               <Slide
                    v-for="(slide, key) in banner"
                    :key="key"
               >
                    <div class="carousel__item w-full cursor-pointer mx-3">
                         <img 
                              @click="gotoPromotion(slide)" 
                              :src="baseUrl.img+'/'+modifyImagePath(slide.banner)" 
                              alt="" 
                              srcset=""
                              class="w-full h-auto cursor-pointer rounded-lg lg:rounded-xl">
                    </div>
               </Slide>
          </Carousel>
     </div>
</template>
<script>
import { ref, computed, watch } from 'vue';
import { promoStore } from "store/promotion";
import { baseUrl } from "config/services.js";
import { Carousel, Slide } from "vue3-carousel";
import { useRouter } from "vue-router";

export default {
     components: {
          Carousel,
          Slide,
     },
     setup() {
          const banner = ref(null);
          const router = useRouter();
          
          const usePromoStore = promoStore();

          const bannerData = computed(() => {
               if (usePromoStore.banner) {
                    banner.value = usePromoStore.banner;
               }
               return usePromoStore.banner || null;
          });

          const gotoPromotion = (item) => {
               router.push({
                    path:
                         "/promotion/detail/" +
                         item.title.replaceAll(" ", "-") +
                         "-" +
                         item.title_highlight.replaceAll(" ", "-") +
                         "-" +
                         item.id,
               });
          };

          const modifyImagePath = (path) => {
               const img = path.split('.');
               return img[0]+'-medium.'+img[1]
          }
          
          watch(bannerData, (newVal) => {
               if (newVal) {
                    banner.value = newVal;
               }
          });

          return {
               banner,
               baseUrl,
               gotoPromotion,
               modifyImagePath
          }
     }
}
</script>